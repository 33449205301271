import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Layout from 'components/layout';
import HeroImage from 'components/hero/front/image';
import ResolveSlices from 'components/slices/ResolveSlices';
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews';
import linkResolver from 'helpers/linkResolver';

function Page(props) {
  const { data } = props;
  const doc = data.prismicPage.data;
  if (!doc) return null;
  return (
    <Layout>
      {doc.banner_image && doc.banner_image.url && (
        <HeroImage displayLogo={false} imageUrl={doc.banner_image.url} />
      )}
      {doc.body && <ResolveSlices data={doc.body} />}
    </Layout>
  );
}

export default withPrismicPreview(Page, [
  {
    repositoryName: 'rectory-housing',
    linkResolver,
  },
]);

Page.propTypes = {
  data: PropTypes.object.isRequired,
};

export const query = graphql`
  query StandardPageQuery($id: String!) {
    prismicPage(id: { eq: $id }) {
      _previewable
      uid
      data {
        banner_image {
          alt
          url
        }
        page_title {
          html
          raw
          text
        }
        body {
          ... on PrismicPageDataBodyTitleAndText {
            slice_type
            primary {
              alignment
              content_grid_width
              display_icon_below_title
              title {
                html
                raw
                text
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodyAllDevelopments {
            slice_type
            primary {
              portfolio_or_active_developments
            }
            items {
              development {
                uid
                type
                document {
                  ... on PrismicDevelopment {
                    id
                    data {
                      archive_status
                      listing_images {
                        image {
                          url
                          alt
                        }
                      }
                      development_logo {
                        url
                        alt
                      }
                      title {
                        html
                        raw
                        text
                      }
                      development_short_detail {
                        html
                        raw
                        text
                      }
                      development_status {
                        html
                        raw
                        text
                      }
                      development_location {
                        html
                        raw
                        text
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyForthcomingDevelopments {
            slice_type
            items {
              development {
                uid
                type
                document {
                  ... on PrismicDevelopment {
                    data {
                      title {
                        html
                        raw
                        text
                      }
                      development_location {
                        html
                        raw
                        text
                      }
                      development_status {
                        html
                        raw
                        text
                      }
                      preview_image {
                        url
                        alt
                      }
                    }
                  }
                }
              }
            }
          }
          ... on PrismicPageDataBodyImageAndContentBlock {
            slice_type
            primary {
              image_alignment
              image {
                alt
                url
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodyVideo {
            slice_type
            primary {
              video {
                embed_url
              }
              video_caption {
                html
                raw
                text
              }
              video_thumbnail {
                alt
                url
              }
            }
          }
          ... on PrismicPageDataBodyContentCarousel {
            slice_type
            items {
              title {
                html
                raw
                text
              }
              icon {
                url
                alt
              }
              intro_paragraph {
                html
                raw
                text
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodyFullWidthImage {
            slice_type
            primary {
              content_layout_theme
              title {
                html
                raw
                text
              }
              link {
                uid
                type
                id
              }
              image {
                url
                alt
              }
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBody13Content23Images {
            slice_type
            primary {
              content {
                html
                raw
                text
              }
            }
            items {
              image {
                alt
                url
              }
            }
          }
          ... on PrismicPageDataBodyLineSpacer {
            slice_type
            primary {
              color
              thickness
            }
          }
          ... on PrismicPageDataBodyMeetTheTeam {
            id
            slice_type
            primary {
              style
            }
            items {
              job_title
              name
              image {
                alt
                url
              }
            }
          }
          ... on PrismicPageDataBodyIconBulletList {
            slice_type
            primary {
              image {
                alt
                url
              }
            }
            items {
              icon {
                alt
                url
              }
              bullet_content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBodyContactForm {
            slice_type
            primary {
              recipient_email
              image {
                alt
                url
              }
            }
          }
          ... on PrismicPageDataBodyContactBlock {
            slice_type
            items {
              email_address
              opening_hours
              phone_number
            }
          }
          ... on PrismicPageDataBodyNewsListing {
            slice_type
            primary {
              order_by
              show_how_many_articles
            }
          }
          ... on PrismicPageDataBodyContentBlock {
            slice_type
            primary {
              content {
                html
                raw
                text
              }
            }
          }
          ... on PrismicPageDataBody5050ColumnContentBlock {
            slice_type
            primary {
              title {
                html
                raw
                text
              }
            }
            items {
              content_block {
                html
                raw
                text
              }
            }
          }
        }
      }
    }
  }
`;
